import { dateLocales, getDefaultLocale } from 'shared/src/date-fns-locales';
import { parseISO, isValid, format as formatFNS, parse as parseFNS } from 'date-fns';
import { take } from 'ramda';
export const formats = {
    localizedNiceDate: 'eeee, d MMMM',
    localizedDate: 'P',
    localizedDateTime: 'P pp',
    isoDateTime: 'yyyy-MM-dd HH:mm:ss',
    yearMonthDay: 'yyyy-MM-dd',
    yearMonth: 'yyyy-MM',
    hoursMinutesSeconds: 'HH:mm:ss',
};
function determineLocale(locale) {
    return locale
        || (typeof window !== 'undefined' ? localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('lastUserLocale') : undefined)
        || getDefaultLocale();
}
export function format(date, formatString, locale) {
    const localeOrDefaultLocale = determineLocale(locale);
    const shortLocale = take(2, localeOrDefaultLocale);
    return formatFNS(date, formatString, {
        locale: dateLocales[shortLocale],
    });
}
export function parse(dateFormatted, formatString, locale) {
    const localeOrDefaultLocale = determineLocale(locale);
    const shortLocale = take(2, localeOrDefaultLocale);
    return parseFNS(dateFormatted, formatString, new Date(), {
        locale: dateLocales[shortLocale],
    });
}
export function parseDate(date) {
    let parsedDate;
    if (typeof date === 'string') {
        parsedDate = parseISO(date);
    }
    else {
        parsedDate = date;
    }
    if (!isValid(parsedDate)) {
        throw new Error(`[parseDate] date is not valid ("${date}")`);
    }
    return parsedDate;
}
export function fromMonthString(monthString, locale) {
    return parse(monthString, formats.yearMonth, locale);
}
export function fromLocalDateTime(dateFormatted, locale) {
    return parse(dateFormatted, formats.localizedDateTime, locale);
}
export function toNiceDate(date, locale) {
    return format(parseDate(date), formats.localizedNiceDate, locale);
}
export function toLocalDate(date, locale) {
    return format(parseDate(date), formats.localizedDate, locale);
}
export function toLocalDateTime(date, locale) {
    return format(parseDate(date), formats.localizedDateTime, locale);
}
export function toISODateTime(date) {
    return format(parseDate(date), formats.isoDateTime);
}
export function toYearMonthDay(date) {
    return format(parseDate(date), formats.yearMonthDay);
}
export function toYearMonth(date) {
    return format(parseDate(date), formats.yearMonth);
}
export function toHoursMinutesSeconds(date) {
    return format(parseDate(date), formats.hoursMinutesSeconds);
}
